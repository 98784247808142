import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToolBarChip } from '../../../common/layout/tool-bar/tool-bar-chip.model';
import { ToolBarPageMixin } from '../../../common/layout/tool-bar/tool-bar-page.mixin';
import { DateRangeDialog } from '../../../dialogs/date-range-dialog/date-range-dialog.component';
import { RouterIcon } from '../../../root/model/root.model';
import { ApiService } from '../../../services/api/api.service';
import { DataTableLanguageService } from '../../../services/data-table-language/data-table-language.service';
import { DateTimeService } from '../../../services/date-time.service';
import { DialogService } from '../../../services/dialog/dialog.service';
import { SettingsService } from '../../../services/user/settings.service';
import { GroupByType } from '../../../shared/models/page-view';
import { SelectDevicesDialog } from 'src/app/dialogs/select-devices-dialog/select-devices-dialog.component';
import { Device } from 'src/app/shared/models/device';
import { ensureLoaded } from 'src/app/services/api/cached-api';

@Component({ templateUrl: './device-connections.component.html' })
export class DeviceConnectionsComponent
    extends ToolBarPageMixin
    implements OnInit
{
    datePageViewStats = {};
    startDate: Date = null;
    endDate: Date = null;
    sections = [
        {
            id: 'date',
            title: 'utilities.dates',
            icon: 'calendar-range',
        },
        {
            id: 'hour',
            title: 'utilities.hours',
            icon: 'clock-outline',
        },
        {
            id: 'feature',
            title: 'utilities.features',
            icon: 'shape-outline',
        },
        {
            id: 'path',
            title: 'utilities.path',
            icon: 'link-variant',
        },
        {
            id: 'device',
            title: 'device.plural',
            icon: RouterIcon.DEVICES,
        },
    ];
    selectedIndex = 0;
    tableOptions;
    devices: Device[] = [];
    deviceId: number = null;

    get selectedSectionId(): GroupByType {
        return this.sections[this.selectedIndex].id as GroupByType;
    }

    get selectedSectionColumnName(): string {
        switch (this.selectedSectionId) {
            case 'date':
                return 'utilities.date';
            case 'hour':
                return this.dateTimeService.isUtcTime
                    ? 'utilities.hour.utc'
                    : 'utilities.hour';
            case 'feature':
                return 'utilities.feature';
            case 'path':
                return 'utilities.path';
            case 'device':
                return 'device';
        }
    }

    get dateRangeString() {
        const toString = (d) => this.dateTimeService.dateString(d);
        const startDateString = toString(this.startDate);
        let endDateString = '';
        if (this.endDate) {
            endDateString = toString(this.endDate);
        }
        if (this.startDate == this.endDate) {
            return startDateString;
        }

        return startDateString + ' - ' + endDateString;
    }

    constructor(
        private dataTableLanguageService: DataTableLanguageService,
        private api: ApiService,
        private translate: TranslateService,
        private dateTimeService: DateTimeService,
        private dialog: DialogService,
        private settings: SettingsService,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await ensureLoaded([this.api.devices]);
        const aYearAgo = new Date();
        aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
        this.startDate = aYearAgo;
        this.endDate = new Date();
        this.devices = this.api.devices.current();
        // Let the date format setting load.
        this.settings.loaded$.subscribe(() => {
            this.setupToolBar();
            this.loadTable();
        });
    }

    setupToolBar() {
        this.clearToolBar();
        this.toolBarChips = [
            new ToolBarChip({
                id: 'dateRange',
                icon: 'calendar-range',
                text: this.dateRangeString,
                callback: () => this.selectDate(),
            }),
            new ToolBarChip({
                id: 'device',
                icon: RouterIcon.DEVICES,
                text: 'device.all',
                callback: () => this.selectDevice(),
            }),
        ];
    }

    async selectDevice() {
        const deviceId = await this.dialog.open(SelectDevicesDialog, {
            data: {
                title: 'btn.select_device',
                devices: this.devices,
                selectedDeviceIds: [this.deviceId],
                multiple: false,
            },
        });
        if (deviceId) {
            const device = this.api.devices.get(deviceId);
            this.deviceId = deviceId;
            this.updateToolBar('device', 'text', device.identifier);
        } else {
            this.deviceId = null;
            this.updateToolBar('device', 'text', 'device.all');
        }
        this.loadTable();
    }

    async selectDate() {
        const dateResponse = await this.dialog.open(DateRangeDialog, {
            width: 'auto',
            data: {
                title: 'btn.select_date_range',
                startDate: this.startDate,
                endDate: this.endDate,
                includeTime: false,
            },
        });
        if (dateResponse) {
            this.startDate = dateResponse.startDate;
            if (dateResponse.end) {
                this.endDate = dateResponse.endDate;
            } else {
                this.endDate = new Date();
            }
            this.updateToolBar('dateRange', 'text', this.dateRangeString);
            this.loadTable();
        }
    }

    async loadTable() {
        const translated = await this.translate
            .get([
                this.selectedSectionColumnName,
                'common.count',
                'device.unknown',
                'utilities.page_views',
            ])
            .toPromise();
        let defaultOrder = [];
        switch (this.selectedSectionId) {
            case 'date':
                defaultOrder = [[0, 'desc']];
                break;
            case 'hour':
                defaultOrder = [[0, 'asc']];
                break;
            default:
                defaultOrder = [[1, 'desc']];
        }
        this.tableOptions = {
            destroy: true,
            responsive: { details: false },
            columns: [
                {
                    data: 'displayValue',
                    title: translated[this.selectedSectionColumnName],
                    render: (data, type) => {
                        if (type == 'display') {
                            if (
                                this.selectedSectionId == 'date' &&
                                typeof data == 'string'
                            ) {
                                const [year, month, day] = data.split('-');
                                const parsedYear = Number(year);
                                const parsedMonth = Number(month);
                                const parsedDay = Number(day);
                                if (
                                    !isNaN(parsedYear) &&
                                    !isNaN(parsedMonth) &&
                                    !isNaN(parsedDay)
                                ) {
                                    const date = new Date(
                                        parsedYear,
                                        parsedMonth - 1,
                                        parsedDay,
                                    );
                                    return this.dateTimeService.dateString(
                                        date,
                                    );
                                }
                            } else if (
                                this.selectedSectionId == 'device' &&
                                data == null
                            ) {
                                return translated['device.unknown'];
                            }
                            return data;
                        } else {
                            if (this.selectedSectionId == 'date') {
                                return new Date(data);
                            }
                            return data;
                        }
                    },
                },
                {
                    data: 'count',
                    title: translated['common.count'],
                    width: '20%',
                },
            ],
            order: defaultOrder,
            ajax: async (_, callback) => {
                const timezoneOffset = this.dateTimeService.isUtcTime
                    ? 0
                    : new Date().getTimezoneOffset();
                const requestData = {
                    groupBy: this.selectedSectionId,
                    timezoneOffset,
                    startDate: this.startDate,
                    endDate: this.endDate,
                };
                if (this.deviceId) {
                    requestData['deviceId'] = this.deviceId;
                }
                const connectionStatsData =
                    await this.api.devices.getStats(requestData);
                callback({ data: connectionStatsData });
            },
            deferRender: true,
            info: false,
            paging: false,
            searching: false,
            language: this.dataTableLanguageService.getLanguageData(),
        };
    }
}
