<h1 mat-dialog-title>{{ 'device.create' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div [formGroup]="form" class="flex flex-col gap-2">
        <div>
            <div class="mat-subtitle-2">
                {{ 'device.identifier' | translate }}
            </div>
            <sz-input formControlName="identifier" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'customer' | translate }}
            </div>
            <sz-select
                formControlName="customerId"
                [options]="customerOptions"
            />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'device.cpu_id' | translate }}
            </div>
            <sz-input formControlName="cpuId" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'device.uuid' | translate }}
            </div>
            <sz-input formControlName="uuid" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'device.serial_number' | translate }}
            </div>
            <sz-input formControlName="serialNumber" />
        </div>
        <button
            mat-flat-button
            class="!text-white"
            color="primary"
            (click)="generateUUID()"
        >
            <mat-icon svgIcon="identifier" class="btn-icon"></mat-icon>
            <span>
                {{ 'device.generate_ids' | translate }}
            </span>
        </button>
    </div>
    <sz-dialog-button-bar
        [primaryDisabled]="!form.valid"
        (primaryClick)="dialogRef.close(form.value)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
