<sz-tool-bar [chips]="toolBarChips" />
<div class="scroll-content flex flex-col gap-3 sm:flex-row">
    <sz-section-selector
        class="m-2 sm:m-3"
        [sections]="sections"
        [(selectedIndex)]="selectedIndex"
        (selectedIndexChange)="loadTable()"
    />
    <div class="flex w-full justify-center overflow-y-scroll">
        <sz-table class="m-3 w-full sm:w-1/2" [options]="tableOptions" />
    </div>
</div>
